import { CircleTopic } from '../types/Circle';
import { useFirebaseFunction } from './cf';
import { ONE_MINUTE } from './time';

// const getLatestCircleTopics = (limit: number) => {
//   return callFirebaseFunction<CircleTopic[]>('circle-getLatestTopics', {
//     // general and wins
//     spaceIds: [2911, 17939],
//     limit
//   });
// };

const CIRCLE_SPACES = {
  GENERAL: 2911,
  WINS: 17939,
  INTROS: 2890,
  SHARE_YOUR_STUFF: 2910,
  WRITING: 2912
};

export const useLatestCircleTopics = (limit: number) => {
  return useFirebaseFunction<CircleTopic[]>(
    'circle-getLatestTopics',
    {
      spaceIds: [
        CIRCLE_SPACES.GENERAL,
        CIRCLE_SPACES.WINS,
        CIRCLE_SPACES.WRITING,
        CIRCLE_SPACES.SHARE_YOUR_STUFF
      ],
      limit
    },
    [limit],
    ONE_MINUTE * 10
  );
};

export const useLatestCircleIntroductionTopics = (limit: number) => {
  return useFirebaseFunction<CircleTopic[]>(
    'circle-getLatestTopics',
    {
      spaceIds: [2890],
      limit
    },
    [limit],
    ONE_MINUTE * 10
  );
};
