import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { times } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { MessageSquare, ThumbsUp } from 'react-feather';
import { randomBetween } from '../services/random';
import styled from '../styled';
import { CircleTopic } from '../types/Circle';
import { AvatarLoader, AVATAR_SIZES } from './Avatar';
import { LinkExternal } from './LinkExternal';
import { UserAvatarLazy, UserName } from './UserAvatar';

const Container = styled('div')((p) => ({
  background: p.theme.palette.grey[700],
  borderBottom: `1px solid #191c20`,
  padding: p.theme.spacing(3),

  ':first-child': {
    borderTopLeftRadius: p.theme.custom.borderRadius.unit,
    borderTopRightRadius: p.theme.custom.borderRadius.unit
  },

  ':last-child': {
    borderBottomLeftRadius: p.theme.custom.borderRadius.unit,
    borderBottomRightRadius: p.theme.custom.borderRadius.unit
  }
}));

const Row = styled('div')((p) => ({
  display: 'grid',
  gridTemplateColumns: 'min-content 2fr 50px 50px',
  gridColumnGap: p.theme.spacing(2),
  alignItems: 'center'
}));

const IconContainer = styled('div')((p) => ({
  display: 'flex',
  alignItems: 'center',
  color: p.theme.palette.grey[400],

  '> :not(:first-child)': {
    marginLeft: p.theme.spacing()
  }
}));

const ICON_SIZE = 18;

export const CircleTopicNameList = ({ ds }: { ds: CircleTopic[] }) => {
  return (
    <>
      {ds.map((d) => (
        <Container key={d.id}>
          <LinkExternal href={d.url}>
            <Row>
              <UserAvatarLazy userId={d.userId} size="small" />
              <div>{d.name}</div>
              <IconContainer>
                <ThumbsUp size={ICON_SIZE} />
                <div>{d.likeCount}</div>
              </IconContainer>
              <IconContainer>
                <MessageSquare size={ICON_SIZE} />
                <div>{d.commentCount}</div>
              </IconContainer>
            </Row>
          </LinkExternal>
        </Container>
      ))}
    </>
  );
};

export const CircleTopicNameListLoader = React.memo(
  ({ rows }: { rows: number }) => {
    return (
      <>
        {times(rows, (i) => (
          <Container key={i}>
            <Row>
              <AvatarLoader size="small" />
              <Skeleton
                variant="rect"
                width={randomBetween(200, 350)}
                height={22}
              />
              <IconContainer>
                <ThumbsUp size={ICON_SIZE} />
              </IconContainer>
              <IconContainer>
                <MessageSquare size={ICON_SIZE} />
              </IconContainer>
            </Row>
          </Container>
        ))}
      </>
    );
  }
);

const HeaderRow = styled('div')((p) => ({
  display: 'grid',
  gridTemplateColumns: 'min-content max-content 2fr 50px 50px',
  gridColumnGap: p.theme.spacing(2),
  alignItems: 'center'
}));

const BodyContainer = styled('div')((p) => ({
  position: 'relative',
  maxHeight: 150,
  margin: `0 calc(${AVATAR_SIZES.small}px + ${p.theme.spacing(2)}px)`,
  marginTop: p.theme.spacing(2),
  overflow: 'hidden',

  '&:before': {
    width: '100%',
    height: '30%',
    position: 'absolute',
    left: 0,
    bottom: 0,
    background: `linear-gradient(to bottom, transparent, ${p.theme.palette.grey[700]})`,
    content: "''" // a plain empty string is erased, which will lead to the element being hidden. this is a bug in emotion
  }
}));

export const CircleTopicBodyList = ({ ds }: { ds: CircleTopic[] }) => {
  return (
    <>
      {ds.map((d) => (
        <Container key={d.id}>
          <LinkExternal href={d.url}>
            <HeaderRow>
              <UserAvatarLazy userId={d.userId} size="small" />
              <Typography component="div" variant="subtitle1">
                <UserName userId={d.userId} />
              </Typography>
              <Typography
                component="div"
                variant="subtitle2"
                style={{ fontStyle: 'italic' }}
              >
                {DateTime.fromISO(d.createdAt).toRelative()}
              </Typography>

              <IconContainer>
                <ThumbsUp size={ICON_SIZE} />
                <div>{d.likeCount}</div>
              </IconContainer>
              <IconContainer>
                <MessageSquare size={ICON_SIZE} />
                <div>{d.commentCount}</div>
              </IconContainer>
            </HeaderRow>
            <BodyContainer dangerouslySetInnerHTML={{ __html: d.body }} />
          </LinkExternal>
        </Container>
      ))}
    </>
  );
};

export const CircleTopicBodyListLoader = ({ rows }: { rows: number }) => {
  return (
    <>
      {times(rows, (i) => (
        <Container key={i}>
          <HeaderRow>
            <AvatarLoader size="small" />

            <Skeleton
              variant="rect"
              width={randomBetween(80, 130)}
              height={32}
            />
            <Skeleton
              variant="rect"
              width={randomBetween(50, 80)}
              height={22}
            />
            <IconContainer>
              <ThumbsUp size={ICON_SIZE} />
            </IconContainer>
            <IconContainer>
              <MessageSquare size={ICON_SIZE} />
            </IconContainer>
          </HeaderRow>
          <BodyContainer>
            <Skeleton
              variant="rect"
              width={randomBetween(400, 600)}
              height={150}
            />
          </BodyContainer>
        </Container>
      ))}
    </>
  );
};
