import { Typography } from '@material-ui/core';
import { sortBy } from 'lodash';
import React from 'react';
import {
  CircleTopicBodyList,
  CircleTopicBodyListLoader,
  CircleTopicNameList,
  CircleTopicNameListLoader
} from '../components/CircleTopicList';
import { onlyOnClientSide } from '../components/ClientSidePage';
import { EventMeta } from '../components/Event';
import { LinkExternal } from '../components/LinkExternal';
import { MiniButton } from '../components/MiniButton';
import { QuestionsGridSection } from '../components/QuestionsGridSection';
import { SEO } from '../components/SEO';
import Layout, {
  Grid,
  GridSection,
  GridSectionBody,
  GridSectionHeading,
  MainHeader,
  Wrapper
} from '../layouts/Layout';
import {
  useLatestCircleIntroductionTopics,
  useLatestCircleTopics
} from '../services/circle';
import { useCurrentUser } from '../services/currentUser';
import { useMappedLoadingValue } from '../services/db';
import { useEvents } from '../services/events';
import { toUserName } from '../services/usersPublic';

const ALL_TOPICS_LIMIT = 8;
const INTRO_TOPICS_LIMIT = 5;

const IndexPage = () => {
  const { isAuthenticated, loadingUser, user } = useCurrentUser();

  const [allTopics, loading, error] = useLatestCircleTopics(ALL_TOPICS_LIMIT);
  const [
    introTopics,
    loadingIntro,
    errorIntro
  ] = useLatestCircleIntroductionTopics(INTRO_TOPICS_LIMIT);

  const [nextEvents] = useMappedLoadingValue(useEvents(), (es) =>
    sortBy(
      es.filter((e) => e.data.date.toMillis() > Date.now()),
      (e) => e.data.date.toMillis()
    ).slice(0, 2)
  );

  return (
    <Layout>
      <SEO
        title="Profile"
        siteUrl="https://bloggingfordevs.com"
        description="Your Dashboard"
        pathname="dashboard/"
        noIndex
      />
      <Wrapper style={{ maxWidth: '1280px' }}>
        <MainHeader
          title={`Welcome, ${user ? toUserName(user.user) : '...'} 👋`}
          subtitle="It's great to have you in the community."
        />
        {isAuthenticated && user && (
          <Grid>
            <div>
              <GridSection>
                <GridSectionHeading>
                  <div>Recent Topics</div>
                  <MiniButton href="https://community.bloggingfordevs.com/c/general">
                    Show all
                  </MiniButton>
                </GridSectionHeading>
                <GridSectionBody>
                  {loading && (
                    <CircleTopicNameListLoader rows={ALL_TOPICS_LIMIT} />
                  )}
                  {!!allTopics && <CircleTopicNameList ds={allTopics} />}
                </GridSectionBody>
              </GridSection>

              <GridSection>
                <GridSectionHeading>
                  <div>Welcome New Members</div>
                  <LinkExternal href="https://community.bloggingfordevs.com/c/introductions">
                    <MiniButton>Show all</MiniButton>
                  </LinkExternal>
                </GridSectionHeading>
                <GridSectionBody>
                  {loadingIntro && (
                    <CircleTopicBodyListLoader rows={INTRO_TOPICS_LIMIT} />
                  )}
                  {!!introTopics && <CircleTopicBodyList ds={introTopics} />}
                </GridSectionBody>
              </GridSection>
            </div>
            <div>
              <GridSection>
                <GridSectionHeading>Upcoming Events</GridSectionHeading>
                <GridSectionBody>
                  {!!nextEvents &&
                    nextEvents.map((d) => <EventMeta d={d} key={d.id} />)}
                </GridSectionBody>
              </GridSection>

              <GridSection>
                <GridSectionHeading>Latest Member Resources</GridSectionHeading>
              </GridSection>

              <QuestionsGridSection />
            </div>
          </Grid>
        )}
        {!isAuthenticated && !loadingUser && (
          <Typography variant="body1">
            You need to log in to see this page
          </Typography>
        )}
      </Wrapper>
    </Layout>
  );
};

export default onlyOnClientSide(IndexPage);
